/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneEdge as ResolvedStandaloneEdge } from 'base/components/standalone/Edge';

export const Edge: typeof ResolvedStandaloneEdge = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneEdge {...props} />
        </ErrorBoundary>
    );
});

export type EdgeProps = PropsFromComponent<typeof Edge>;

/** @deprecated Use slot architecture instead */
export const EdgeWith = (extras: DynamicStandaloneExtras): typeof Edge => {
    return function Edge(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Edge');
        return <ResolvedStandaloneEdge {...mergeProps({ options: { theme } }, props)} />;
    }
}